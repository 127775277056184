<template>
  <div>
    <b-form-group
      id="input-code-group"
      label="Scripta Code:"
      label-for="input-code"
    >
      <b-form-input
        id="input-code"
        v-model="model.code_new"
        placeholder="Code"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-name-group"
      label="Full Name:"
      label-for="input-name"
    >
      <b-form-input
        id="input-name"
        v-model="model.name"
        placeholder="Name"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-language-group"
      label="Language Code:"
      label-for="input-language"
    >
      <select
        id="input-language"
        placeholder="Language"
        v-model="model.language_code"
        >
        <option></option>
        <option>esp.</option>
        <option>fr.</option>
        <option>frpr.</option>
        <option>gasc.</option>
        <option>lt.</option>
        <option>occ.</option>
        <option>protger.</option>
      </select>
    </b-form-group>

    <b-form-group
      id="input-comment-group"
      label="Comment:"
      label-for="input-comment"
    >
      <b-form-textarea
        id="input-comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'ScriptaNewEdit',
    props: ['scripta'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.scripta ? this.scripta : {
          code_new: "",
          language_code: "",
          name: "",
          comment: ""
        })
      }
    },
    updated(){
      HelpStore.item = this.model
    }
  }
</script>
