<template>
  <div>
    <PageTitle title="Scriptae" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button variant="primary" v-if="config.editable" size="sm" :disabled="!$store.getters.checkRole('legam.bib.scripta.add')" class="float-right mb-4" v-b-modal.modal-new-scripta>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new scripta
          </b-button>
          <b-modal id="modal-new-scripta" v-if="config.editable" title="New scripta" ok-title="Save" @ok="createScripta" size="lg">
            <ScriptaNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            @click="tableAction"
            @selectChanged="selectChanged"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            @click="openDetails"
          />
        </b-col>
      </b-row>
    </b-container>

    <div class="merge-float" v-if="selected.length">
      <b-badge href="#" variant="info" class="mr-1" v-for="scripta in selected" :key="scripta.code" @click="removeSelected(scripta)">
        <span v-html="scripta.code"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge scriptas
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge scriptas" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="scripta in selected" :key="'modal' + scripta.code" @click="removeSelected(scripta)">
          <span v-html="scripta.code"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          code="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This scripta will keep all it's data (other scriptas will move their data to this scripta and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(scripta => {
              return {
                'text': scripta.code,
                'value': scripta.code,
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction";
  import tdColSafeHTML from "@/components/tdColSafeHTML"
  import ScriptaNewEdit from "@/components/Main/ScriptaNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'
  // import tdColUUID from "@/components/tdColUUID"

  export default {
    name: 'ScriptaeList',
    data(){
      return {
        columns: [
          { title: this.$t('Code'), field: 'code', sortable: true, searchable: true },
          { title: this.$t('ScriptaFullName'), field: 'name', sortable: true, searchable: true },
          { title: this.$t('LanguageCode'), field: 'languageCode', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row) {
              return [
                  {
                    text: null,
                    icon: 'list-ul',
                    id: row.id,
                    to: {name: 'ScriptaDetails', params: {
                      id: config.editable ? row.code : row.id
                    }}
                  }
                ]
            }
          }
        ],
        columnsPI: [
          { title: this.$t('Code'), field: 'code', sortable: true, searchable: true },
          { title: this.$t('FullName'), field: 'name', sortable: true, searchable: true },
          { title: this.$t('LanguageCode'), field: 'languageCode', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true }
        ],
        url: '/scriptas/datatables',
        key: 1,
        selected: [],
        mainEntityId: null,
        searchableByColumn: true,
        scriptaModel: null,
        scriptaModelKey: 0,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      ScriptaNewEdit
    },
    methods: {
      createScripta(){
        this.$store.dispatch('createScripta', HelpStore.item).then(() => this.key++)
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].code
        }
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.code != _item.code)
      },
      openDetails(data) {
        router.push(`/scripta/${data.id}`)
      },
      tableAction(action) {
        action = JSON.parse(action)
        if (action.action == 'delete') {
          this.$store.dispatch('deleteScripta', {
            id: action.id
          }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Scripta deleted`
            })
          this.key++
          })
        } else if (action.action == 'edit') {
          this.scriptaModel = action.scripta
          this.scriptaModelKey++
          this.$bvModal.show('modal-edit-scripta')
        }
      },
      merge() {
        this.$store.dispatch('mergeScriptas', {
          mainScriptaId: this.mainEntityId,
          scriptaIds: this.selected.filter(scripta => scripta.code != this.mainEntityId).map(scripta => scripta.code)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Scriptae merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
